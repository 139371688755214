<template>
    <div class="container">
        <div class="row ">
            <div class="col mb-2">
                <div class="card">
                    <img v-if="employee.foto" :src="base_api_url+'/images/'+employee.foto" alt="Profile picture" style="width:150px; height:150px;">
                    <div class="card-body">
                        <h5 class="card-title">{{employee.nome}} {{employee.cognome}}</h5>
                        <p>{{employee.ruolo}}</p>
                        <p>{{employee.email}}</p>
                        <a v-if="employee.allegato" :href="base_api_url +'/documents/'+employee.allegato">Curriculum vitae</a>
                    </div>
                    <ul v-for="link in employee.links" :key="link.id" class="list-group list-group-flush">
                        <li class="list-group-item"><a :href="link.link">{{link.etichetta}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-sm-9">
                <div class="card">
                    <h5 class="card-header">{{$t('presentazione')}}</h5>
                    <div class="card-body">
                        <p  v-if="localLanguage === 'en'" class="card-text">{{employee.presentazione_en}}</p>
                        <p  v-else class="card-text">{{employee.presentazione_it}}</p>
                    </div>
                </div>
                <div class="card">
                    <h5 class="card-header">{{$t('corsi')}}</h5>
                    <ul  class="list-group list-group-flush">
                        <li class="list-group-item">{{$t('anno')}}: <a :href="'http://www-4.unipv.it/offertaformativa/portale/docente.php?lingua=1&annoCorrente='+ (employee.anno_corrente -1) +'/'+employee.anno_corrente+'&matricolaDocente='+employee.matricola" target="_blank"> {{(employee.anno_corrente -1) +'/'+employee.anno_corrente }}</a></li>
                        <li class="list-group-item">{{$t('anno')}}: <a :href="'http://www-4.unipv.it/offertaformativa/portale/docente.php?lingua=1&annoCorrente='+ (employee.anno_corrente -2) +'/'+ (employee.anno_corrente -1 )+'&matricolaDocente='+employee.matricola" target="_blank">{{(employee.anno_corrente -2) +'/'+ (employee.anno_corrente -1 )}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <div class="card">
                    <h5 class="card-header">{{$t('contatti')}}</h5>
                    <ul v-for="contact in employee.contacts" :key="contact.id" class="list-group list-group-flush">
                        <li v-if="localLanguage == 'en'" class="list-group-item">{{contact.etichetta_en}}: {{contact.info}}</li>
                        <li v-else class="list-group-item">{{contact.etichetta_it}}: {{contact.info}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { computed, onMounted,ref,inject} from 'vue';
import { useRoute } from 'vue-router';
import eServices from "../services/employees.service";
import { onBeforeRouteLeave} from 'vue-router'
import {i18n} from '../plugins/i18n';


export default {
    name:"Card",
    setup(){
        const route = useRoute();

        const employee = ref([]);
        
        const id = computed(() => {
            let id;

            if (localStorage.getItem("employeeID") === null) {

                id = route.params.id
                localStorage.setItem("employeeID",id);

            }else{

                id = localStorage.getItem("employeeID");

            }
            
            return id;
        });

        const localLanguage = computed(() => {return i18n.global.locale});

        const base_api_url = inject('api_url');

        console.log(base_api_url);

        const LoadEmployee = () =>{
            eServices.getEmployee(id.value).then(
                (response) => {
                    employee.value = response.data;
                    console.log(employee.value)
                },
                (error) => {
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
            );
        }

        onMounted(LoadEmployee);

        onBeforeRouteLeave(()=>{

            window.localStorage.removeItem('employeeID');

        });

        return {
            employee,
            id,
            localLanguage,
            base_api_url
        }
    }

}
</script>

<style scoped>
.card{
    margin-bottom: 20px;
}
</style>